import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
import messages from '@intlify/unplugin-vue-i18n/messages';
import * as Sentry from '@sentry/vue';
import { AppContextPlugin } from '@tundr/app-context';
import { AuthPlugin, useAuthStore } from '@tundr/auth';
import { BreadcrumbPlugin } from '@tundr/breadcrumb';
import { HttpPlugin, HttpStatusCode } from '@tundr/http';
import { generateI18nInstance, I18nPlugin } from '@tundr/i18n';
import tundrFavicon from '@tundr/theme-assets/favicon/tundr-favicon.png';
import '@tundr/theme-globals';
import { ValidationPlugin } from '@tundr/validation';
import { useFavicon } from '@vueuse/core';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';
import { version } from '../appVersion.json';
import App from './App.vue';
import { ENV_VARS, Environments } from './core/config/env';
import { appRoutesDictionary } from './core/router/app-routes.dictionary.ts';
import { routes } from './core/router/routes';
import Clarity from '@microsoft/clarity';
import { useFiltersStore } from '@tundr/table';

const icon = useFavicon();

icon.value = tundrFavicon;

const pinia = createPinia();

const app = createApp(App);

app.use(AppContextPlugin);

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const filtersStore = useFiltersStore();

  const getBasePath = (path: string) => {
    const segments = path.split('/');
    return segments.length > 2 ? segments[2] : '';
  };
  const isBasePathChanging = getBasePath(to.path) !== getBasePath(from.path);

  if (isBasePathChanging) {
    filtersStore.resetAllFilters();
  }

  next();
});

app.use(pinia);

const authStore = useAuthStore();

const i18n = generateI18nInstance({
  messages,
  debugMode: ENV_VARS.node === 'development',
});
app.use(I18nPlugin, { i18n });

app.use(ValidationPlugin, { i18n: i18n.global });

app.provide('appVersion', version);

app.use(router);

app.use(BreadcrumbPlugin, { resetPath: appRoutesDictionary.root.app.path });

app.use(HttpPlugin, { baseURL: ENV_VARS.api.baseUrl });

app.use(AuthPlugin, { configureCompanyIdInHeaders: true });

app.use(autoAnimatePlugin);

if (ENV_VARS.environment === Environments.PRODUCTION) {
  Clarity.init('prnw79a7m0');
}

if (
  ENV_VARS.environment !== Environments.LOCAL &&
  ENV_VARS.environment !== Environments.DEV
) {
  Sentry.init({
    app,
    dsn: ENV_VARS.sentry.dsn,
    environment: ENV_VARS.environment,
    release: version,
    ignoreErrors: [
      /^Importing a module script failed$/,
      /^Failed to fetch dynamically imported module$/,
    ],
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
    trackComponents: true,
    tracesSampleRate: 1.0,
    attachStacktrace: true,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      const errorCode = (
        hint?.originalException as { response: { status: number } }
      )?.response?.status;
      if (errorCode && errorCode === HttpStatusCode.BadRequest) {
        return null;
      }
      return event;
    },
  });
  Sentry.setUser({
    id: authStore.user?.id,
    username: `${authStore.user?.firstName} ${authStore.user?.lastName}`,
  });
  Sentry.setExtra('companyId', authStore.selectedCompany?.id);
  Sentry.setExtra('companyName', authStore.selectedCompany?.name);
}

router.onError((error) => {
  const isDeployError =
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed');

  if (isDeployError) {
    window.location.reload();
  }
});

app.mount('#app');
